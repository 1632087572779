// Use radial gradient for nav background
.menuMain {
  background: radial-gradient(
    1096.91px at 29.9% 50.3%,
    #d60079 0%,
    #b1006e 100%
  );
  border-radius: 0;
}
// Fix z-index on carousel curve when no carousel
.pageHeader {
  position: relative;
  // z-index: 1; // JK - THIS CAUSES A BUG WITH THE MOBILE MENU: https://www.bugherd.com/projects/160601/tasks/14
}
// Home feature
.homeFeature,
.listedPost,
.feedItem {
  &:hover {
    .homeFeatureDetailsWrapper,
    .listedPostText,
    .feedItemDetailsWrapper {
      transform: translateY(-2rem) !important;
    }
  }
}
// Add border radius to homeFeatureDetailsWrapper and other boxes
.homeFeatureDetailsWrapper,
.listedPost .listedPostText {
  border-radius: 5px 0 0 0;
  box-shadow: 0 -10px 20px rgba(black, 0.05);
}
.feedItemDetailsWrapper {
  border-radius: 5px 0 0 0;
  box-shadow: -10px 0 20px rgba(black, 0.05);
}
// home intro
.homeIntro {
  p {
    strong {
      font-weight: 700;
      color: $brand-secondary;
    }
    &:before {
      content: '';
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: 60px;
      background-image: url($assets-path+'logo-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @media (max-width: map-get($breakpoints, lg)) {
    margin-top: $spacer * 3;
  }
}
// make feeds like features
.feedItem a + [class*='DetailsWrapper']:not(.publishDetailsWrapper),
.feedItem a + [class^='listed'][class$='Text'] {
  height: calc(100% - 1rem);
  align-self: flex-end;
}
// Add curves
.footerBox[class*='ewsletter'] {
  padding-top: $spacer * 5;
}
.carousel,
.homefeaturecategory-homeboximpactstats,
.footerBox[class*='ewsletter'] {
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    left: -10vw;
    width: 120vw;
    height: calc((73 / 1440) * 120vw);
    background-image: url($assets-path+'carousel-curve.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    pointer-events: none;
  }
}
.carousel:after {
  bottom: -2px;
  background-position: center 100%;
}
.homefeaturecategory-homeboximpactstats:after,
.footerBox[class*='ewsletter']:after {
  top: -2px;
  background-position: center 0;
  transform: rotate(180deg) scaleX(-1);
}
.homepage .contentContainer {
  overflow-x: hidden;
}
.homeFeedsWrapper {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: -10vw;
    width: 120vw;
    height: calc((60 / 1440) * 120vw);
    background-image: url($assets-path+'solid-curve.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top: calc(((-60 / 1440) * 120vw) + 1px);
    background-position: center 0;
    transform: scaleX(-1);
    pointer-events: none;
  }
}
// Fix button text vertical alignment
.cta-button,
.button,
button,
.readMore,
.addToCalender,
.menuMainAlt {
  padding: 0.5em 1.5em 0.7em;
}
// Footer stuff
.footerBox {
  a {
    text-decoration: none !important;
  }
}
.footerBox.Footerlinks {
  .footerLinks {
    display: flex;
    justify-content: center;
    li {
      // font-size: 1.125em;
      font-weight: 700;
      a {
        padding: 0.5em 0.5em;
        &:hover {
          color: white;
        }
      }
    }
    @media (max-width: map-get($breakpoints, md)) {
      display: block;
      text-align: center;
    }
  }
}
.footerBox.Footercontactus {
  text-align: center;
}
.footerBox.Footersocialmedia {
  .socialIcons {
    justify-content: center;
  }
}
// Fix header text padding-top
.headerText {
  padding-top: 0;
}
// Fix mobile menuAlt spacing and border radius
@media (max-width: 501px) {
  .mainLogo {
    margin-bottom: 1rem;
  }
  .menuMainAlt {
    border-radius: 0;
  }
  .content.headerContent {
    margin-bottom: 0;
  }
}
// Fix padding on mobile details wrapper
@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideDetail {
    padding-bottom: 2rem;
  }
}
// Background gradients on impact stats and newsletter
.homefeaturecategory-homeboximpactstats,
.footerBox[class*='ewsletter'] {
  background: radial-gradient(368.5px at 50% 50%, #ea3692 0%, #b1006e 100%);
}
// Extra padding to compensate for the curve
.homefeaturecategory-homeboximpactstats {
  padding-bottom: $spacer * 7;
}
// Fix for feed items: the images shouldn't be 100% height.
.feedItem {
  .banner {
    height: auto;
  }
}
// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  margin: $spacer * 2 0;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// Widen post content for directory post - JK
.pagesPostBody.PostSystemCategory_wide-post {
  .post .contentBlockWrapper,
  .post .headerText .title,
  .postContent {
    max-width: $container-max-width;
  }
}


// Re-order items
body.subsite .contentContainer {
  .headerWrapper.headerWrapperSubsite { order: 1; }
  .headerTextSubsite { order: 3; }
  .menuSub { order: 2; }
  .subsiteBody { order: 4; }
  .subsiteFeedsWrapper { order: 5; }
  .carousel:after { display: none; }
}

// Subsite Feeds - Layout
body.subsite .subsiteFeeds {
  max-width: 100%;

  .subsiteFeed {
    width: 100%;
    margin: 0 auto;
    flex: unset;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    .feedList {
      display: flex;
      flex-wrap: wrap;
    }
  }

  
  // Subsite stats
  .subsiteFeedBox2.subsiteFeedsnippets .feedList {
    max-width: 100%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 20%;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%) scaleX(-1);
      background-image: url($assets-path + "solid-curve.svg");
      background-size: 100% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
    }
  }
  .feedItem[class*='stats'] {
      position: relative;
      color: $impact-stats-colour;
      padding: $impact-stats-padding-y 0;
      margin-top: $impact-stats-margin-top;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $impact-stats-margin-bottom;
      border-radius: $impact-stats-border-radius;
      width: 100%;
      max-width: $impact-stats-max-width;
      background-color: $impact-stats-background-colour;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url($assets-path + $impact-stats-background-image);
        z-index: 0;
        background-repeat: $impact-stats-background-image-repeat;
        background-size: $impact-stats-background-image-size;
        background-position: center;
        opacity: $impact-stats-background-image-opacity;
      }
      &:after {
          content: '';
          position: absolute;
          top: -2px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg) scaleX(-1);
          width: 100vw;
          height: 20%;
          background-image: url($assets-path + "carousel-curve.svg");
          background-position: bottom center;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }
      .feedItemDetailsWrapper {
        position: relative;
        width: 100%;
        max-width: $impact-stats-content-max-width;
        padding: 0 $site-bleed;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: $impact-stats-overall-container-direction;
        background-color: transparent !important;
        box-shadow: none;
        > h2 {
          display: block;
          position: relative;
          text-align: $impact-stats-heading-text-align;
          font-size: $impact-stats-heading-font-size;
          color: $impact-stats-heading-colour;
          font-weight: $headings-font-weight;
          margin-bottom: $impact-stats-heading-margin-bottom;
          a { color: inherit; }
        }
      }

      table { position: relative; }

      tbody {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: $impact-stats-direction;
        align-items: flex-start; // https://impactful.slack.com/archives/GED9J1FKK/p1580144828002100
        text-align: $impact-stats-text-align;
      }

      th, td { border-top: 0; }

      tr {
        position: relative;
        text-align: inherit;
        display: flex;
        justify-content: center;
        flex-direction: $impact-stat-direction;
        align-items: center;
        padding: $impact-stat-padding;
        border: $impact-stat-border;
        border-width: $impact-stat-border-width;
        margin: $impact-stat-margin / 2;

        @media (min-width: $impact-stats-breakpoint + 1px) {
          flex: 1;
          min-width: $impact-stat-min-width;
        }

        @media (max-width: $impact-stats-breakpoint) {
          text-align: $impact-stats-mobile-text-align;
        }
      }

      td {
        display: block;
        padding: 0;
        position: relative;
      }

      // Figure
      td:first-child {

        h2, h3, h4 {
          font-size: $impact-stats-figure-font-size;
          font-family: $impact-stats-figure-font-family;
          color: $impact-stats-figure-colour;
          margin: 0;
        }
      }

      td:last-child {
        color: $impact-stats-summary-colour;
        font-size: $impact-stats-summary-font-size;
        font-weight: $impact-stats-summary-font-weight;
        margin-top: $impact-stats-summary-margin-top;
      }
      table, td, tr {
        width: 100% !important;
        height: auto !important;
      }
    }
}

// Remove pseudo element
body.subsite .homeFeedsWrapper:after {
  display: none;
}

// Mailchimp DES-5447
#mc_embed_signup {
  display: block;
  width: 100%;
  max-width: $container-max-width;
  margin: auto;
  padding: 0 20px;
}

#mc_embed_signup_scroll {
  display: flex;
  align-items: flex-end;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  .mc-field-group {
    flex: 1;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    min-width: 200px;
    label {
      display: inline-block;
    }
    input {
      width: 100%;
    }
  }
  .clear {
    display: inline-flex;
    height: 38px;
    margin-bottom: 1rem;
    input {
      color: $body-colour;
    }
  }
}

// Slick slider https://raisingit.atlassian.net/browse/IM-850
.slick-slider {
  overflow: hidden;
}

.slick-slider img {
  padding: 20px;
}

// https://raisingit.atlassian.net/browse/IM-1139
.menuMainAlt {
  font-size: 1.2rem;
}

b, strong {
  font-weight: bold;
}

