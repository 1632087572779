//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card(
    $card-gap-width: 1rem
  );
  @include card-basic(
    $prefab: 5
  );
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

body:not(.subbsite) {
// Choose your overall home feeds layout
  @include home-feeds-prefab (
    $number-of-feeds: 2,
    $prefab: 1
  ) ;
}

// Specify a card layout for each individual feed
.homeFeedBox1:not(.subsiteFeed) .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox2:not(.subsiteFeed) .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// Choose your card style for home feed cards
.homeFeed:not(.subsiteFeed) .feedItem {
  @include card(
    $card-heading-font-size: $font-size-h5,
    $card-summary-font-size: 1rem
    );
  @include card-basic (
    $prefab: 5
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, not embedded map results)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  // .listedPost {
  @include card(
    $card-gap-width: 1rem
  );
  @include card-basic(
    $prefab: 5
  );
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}


// Subsite
body.subsite {
  @include home-feeds-prefab (
    $number-of-feeds: 3,
    $prefab: 1
  ) ;

  .subsiteFeeds .subsiteFeedBox1 {
    @include cards-layout-prefab (
      $number-of-cards: 6,
      $prefab: 3
    );
  }

  .subsiteFeeds .subsiteFeedBox3 {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 2
    );
  }

  .subsiteFeeds .subsiteFeedBox4 {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 3
    );
  }

  .subsiteFeeds .subsiteFeed:not(.subsiteFeedBox2) .feedItem {
    @include card-prefab-flex-column(3);
    @include card(
      $card-gap-width: 1rem,
      $card-footer-background-colour: transparent,
    );
    @include card-basic(
      $prefab: 5
    );
  }
}
