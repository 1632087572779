//
// Environment
//



//
// Config values
//

$platform-name: 'thebridgerenewaltrust';


//
// Colours
//

$brand-primary: rgb(177, 0, 110);
$brand-secondary: rgb(230, 0, 126);

$grey-dark:         #292b2c;
$grey-lighter: #EEECED;
$grey-lightest: #F9F6F7;

// Create greyscale


// Utility colours


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;
$border-radius: 3px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);


// Spacers
$spacer: 1rem;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo-width: 300px;
$logo-height:100px;


//
// Typography
//

$font-family-base: 'Open Sans', sans-serif;
$font-weight-normal: 300;
$font-weight-bold: 700;
$font-size-base: 0.95rem;
$headings-margin-top: 1em; // This will only affect headings within post content

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;


// Links

// Icon font


//
// Buttons
//

$btn-border-radius: 6em;


// Donate button


//
// Social icons
//

$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: brand; // A colour, or 'brand'

// Social network colours - use these by changing $social-icons-use-brand-colours to true


// Header specific
$social-icons-header-colour: brand; // A colour, or 'brand'
$social-icons-header-hover-colour: brand; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-colour: #FFF; // A colour, or 'brand'
$social-icons-footer-hover-colour: brand; // A colour, or 'brand'


//
// Share this page
//



//
// Form elements
//


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-font-size: $font-size-h4;
$card-image-background-colour: white;


// Card text overlay

// Card text over

// Card side by side


// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;


//
// Menu admin
//

$menu-admin-background-colour: $grey-lightest;



//
// Header
//


// Header content

// Tagline

// Main call to action

// Search

// Search - input

// Search - button
$header-search-button-background-colour: transparent;

// Social icons


//
// Sticky header
//



//
// Navigation
//


// Top level items
$nav-top-level-item-colour: white;
$nav-top-level-item-hover-background-colour: $brand-primary;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: rgba(white, 0.5);

// Submenus
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-item-colour: white;

// Burger button
$burger-btn-bar-breakpoint: 501px; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-bottom: 0;
$nav-background-colour: radial-gradient(1096.91px at 29.9% 50.3%, #EA3692 0%, #B1006E 100%);




//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-padding: $spacer*2;
$carousel-details-margin-x: $spacer;
$carousel-details-border-radius: 5px;
$carousel-border-radius: 0;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel after breakpoint


//
// Home intro
//

$home-intro-background-colour: white;
$home-intro-padding-y: 0;
$home-intro-font-weight: 300;
$home-intro-colour: $brand-primary;
$home-intro-font-size: 3em;
$home-intro-margin-top: 8rem;


//
// Home features
//
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 2;



//
// Impact stats
//

$impact-stats-heading-margin-bottom: $spacer;
$impact-stats-background-image: 'figure.svg';
$impact-stats-background-image-opacity: 1;


// Heading

// Individual stat

// Figure

// Summary


//
// Home feeds
//
$home-feeds-padding-top: $spacer * 3;


// Feeds title
$feeds-title-font-size: $font-size-h3;

// Feed item

// Event feed items

// Twitter feed


//
// Footer
//

$footer-prefab: 6;
$footer-background-colour: $grey-dark;
$footer-link-colour: $grey-lighter;


// Footer admin links
$footer-admin-basket-link-enabled: false;

// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-button-background-colour: white;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//



//
// Page title
//

$page-title-margin-top: 2em;



//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts


// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//
$breadcrumb-max-width: $container-max-width;



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//

// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//

$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $brand-primary;



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)


// Departments list

// Product-price

// Product post


//
// Subsite
//
$subsite-enabled: true;
$subsite-logo: null;
$subsite-logo-width: 0;
$subsite-logo-height: 0;
$subsite-logo-margin: 0;

// Subsite nav
$subsite-nav-breakpoint: map-get($breakpoints, lg);
$subsite-nav-max-width: 100%;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: 0;
$subsite-nav-background-colour: $brand-secondary;
$subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour !default;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

//
// Cookie consent
//

//Keeping in touch section
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: false;
$keeping-in-touch-via-sms-enabled: false;
$keeping-in-touch-via-post-enabled: false;